import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import offlineExporting from 'highcharts/modules/offline-exporting';
import exporting from 'highcharts/modules/exporting';
import { Popover as BootstrapPopover } from 'bootstrap';
import ChartInfo from './ChartInfo';
import { SiteAnalysisBase, SectionVisualizationChart } from '../types';
import { getChartOptions } from '../helpers/getChartOptions';

exporting(Highcharts);
offlineExporting(Highcharts);

type Props = {
    containerId: string;
    visualization: SectionVisualizationChart;
    data: SiteAnalysisBase;
    showTitle: boolean;
    isMultiPoint?: boolean;
    exportTitleSuffix: string;
};

const Chart = ({
    containerId,
    visualization,
    data,
    showTitle,
    isMultiPoint = false,
    exportTitleSuffix,
}: Props) => {
    const [chartInstance, setChartInstance] = useState<Highcharts.Chart | null>(
        null,
    );
    // No data labels must be enabled manually
    NoDataToDisplay(Highcharts);

    useEffect(() => {
        const popoverTriggerList = Array.from(
            document.querySelectorAll('[data-bs-toggle="popover"]'),
        );

        popoverTriggerList.map(function (popoverTriggerEl) {
            return new BootstrapPopover(popoverTriggerEl);
        });
    });

    useEffect(() => {
        const options = getChartOptions(visualization, data, exportTitleSuffix);
        const chart = Highcharts.chart(containerId, options);
        if (!chartInstance) {
            setChartInstance(chart);
        }
    }, [containerId, chartInstance, visualization, data, exportTitleSuffix]);

    const headerClass = classNames('text-start', 'px-16', 'px-md-32', {
        invisible: !showTitle,
        'mb-24': visualization.header && visualization.title,
    });
    const titleClass = classNames('text-start', 'px-16', 'px-md-32', {
        invisible: !showTitle,
        'mb-24': visualization.title,
    });
    const chartWrapperClass = classNames('px-16', 'px-md-32', {
        'border-start': !isMultiPoint && !showTitle,
    });

    const infoAndDownloadRowClasses = classNames('row px-16 px-md-32 mx-0 align-items-center');

    return (
        <div className="border-bottom pt-24 pb-12">
            <h3 className={headerClass}>{visualization.header}</h3>
            <h4 className={titleClass}>{visualization.title}</h4>
            <div className={chartWrapperClass}>
                <div id={containerId}></div>
            </div>
            <div className={infoAndDownloadRowClasses} style={{height: 32}}>
                {showTitle && (
                    <div className="col d-flex align-items-center flex-shrink-0 px-0 py-4">
                        <ChartInfo visualizationIds={[visualization.id]} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Chart;
